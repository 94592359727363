@charset "UTF-8";
@keyframes bounceAndGlow {
  from {
    opacity: 1;
    transform: translate(-50%, -100%); }
  to {
    opacity: .8;
    transform: translate(-50%, -115%); } }

@keyframes pulse {
  from {
    opacity: 0.6; }
  to {
    opacity: 0.4; } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideIn {
  from {
    transform: translateY(-3px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1; }
  to {
    transform: translateY(-3px);
    opacity: 0; } }

@keyframes slideInDown {
  from {
    transform: translateY(3px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideInDownBig {
  from {
    transform: translateY(10px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

html, body {
  height: 100%; }

body {
  overflow: hidden;
  font-family: "Quicksand", sans-serif;
  background-color: #e6e6e6; }

body::-webkit-scrollbar {
  display: none; }

body.beta #map:before {
  content: 'beta';
  position: absolute;
  bottom: -8px;
  right: 0;
  font-size: 20px;
  z-index: 1;
  color: #FF8265;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 5px 0; }

body.offline #addPlace,
body.offline .howToInstallBtn,
body.offline #filterBtn,
body.offline #userBtn,
body.offline .loginBtn,
body.offline .createRevisionBtn,
body.offline .rating-input-container,
body.offline .search-button,
body.offline #about-social-buttons,
body.offline #hamburger-menu .side-nav__footer,
body.offline .openReviewPanelBtn,
body.offline #placeDetailsContent .photo-container.loading {
  opacity: 0.4 !important;
  pointer-events: none; }

body.offline #map {
  background: url(/img/blank_map.jpg);
  background-size: cover;
  background-position: 50% 50%; }

body.webview-app .howToInstallBtn,
body.pwa-installed .howToInstallBtn {
  display: none; }

body.position-pin-mode #map:before, body.position-pin-mode #map:after {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 1;
  opacity: 1; }

body.position-pin-mode #map:before {
  top: 0;
  background: linear-gradient(white, transparent); }

body.position-pin-mode #map:after {
  bottom: 0;
  background: linear-gradient(transparent, white); }

body.showMarkerLabels .markerLabel {
  display: block !important; }

hr {
  border-top: 2px dotted #e6e6e6; }

p {
  margin: 0 0 1em; }

#out-of-bounds-overlay {
  background: rgba(4, 136, 58, 0.3);
  pointer-events: none; }
  #out-of-bounds-overlay .message-overlay-label {
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }
  #out-of-bounds-overlay .btn {
    pointer-events: auto;
    color: #fafafa;
    background: transparent;
    border: 2px solid white;
    text-transform: uppercase;
    transition: all 0.2s ease-out; }
    #out-of-bounds-overlay .btn:hover {
      background: #fafafa;
      color: #828b90;
      border: 0; }

#search-overlay {
  background: #fafafa;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 56px;
  left: 0;
  padding: 10px 12px;
  transition: all 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
  opacity: 0;
  visibility: hidden;
  user-select: none;
  font-size: 16px; }
  @media screen and (min-width: 430px) {
    #search-overlay {
      position: absolute;
      height: auto;
      top: 42px;
      height: auto;
      width: 100%;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 12px; } }
  #search-overlay h2 {
    font-size: 14px;
    font-weight: bold;
    color: #828b90; }
  #search-overlay.showThis {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  #search-overlay .openTopCitiesModal {
    color: #30bb6a; }

.top-cities,
#search-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #828b90; }
  .top-cities li button,
  #search-overlay ul li button {
    border: 0;
    background: 0;
    width: 100%;
    text-align: left;
    padding: 12px 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex; }
    .top-cities li button .top-cities--name,
    #search-overlay ul li button .top-cities--name {
      flex: 3; }
      .top-cities li button .top-cities--name span,
      #search-overlay ul li button .top-cities--name span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        color: #828b90; }
    .top-cities li button .top-cities--nbr,
    #search-overlay ul li button .top-cities--nbr {
      opacity: .5;
      flex: 1;
      text-align: right; }
    .top-cities li button:focus, .top-cities li button:active, .top-cities li button:hover,
    #search-overlay ul li button:focus,
    #search-overlay ul li button:active,
    #search-overlay ul li button:hover {
      color: #333;
      background: #F3F3F3; }
    .top-cities li button .glyphicon,
    .top-cities li button img,
    #search-overlay ul li button .glyphicon,
    #search-overlay ul li button img {
      margin-right: 6px; }

#topCitiesModal .top-cities {
  font-size: 18px;
  color: #333; }
  #topCitiesModal .top-cities li {
    width: 48%;
    display: inline-block; }
    #topCitiesModal .top-cities li:nth-child(odd) {
      padding-right: 4%; }
    #topCitiesModal .top-cities li button {
      padding: 12px 0;
      margin: 0; }
      #topCitiesModal .top-cities li button .top-cities--name {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 4px;
        font-weight: bold; }
      #topCitiesModal .top-cities li button .top-cities--nbr {
        color: #828b90;
        font-size: inherit; }

#topCitiesModal .modal-footer {
  padding: 36px 36px;
  color: white;
  background: #30bb6a; }
  #topCitiesModal .modal-footer img {
    max-width: 10%;
    align-self: flex-start;
    height: auto;
    padding-bottom: 12px; }
  #topCitiesModal .modal-footer h2 {
    margin-top: 0; }

.message-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  transition: opacity 0.5s ease-in-out; }
  .message-overlay.showThis {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  .message-overlay .message-overlay-content {
    width: 300px;
    padding: 0 20px; }
  .message-overlay .message-overlay-label {
    font-size: 20px;
    color: #828b90;
    text-align: center;
    margin-bottom: 12px; }
  .message-overlay .btn {
    width: 100%;
    margin-top: 6px; }

.loading,
.featherlight-loading {
  opacity: 0.8; }
  .loading:after,
  .featherlight-loading:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #30bb6a;
    animation: spinner .6s linear infinite; }

.tooltip {
  font-family: "Quicksand", sans-serif;
  z-index: 20; }
  .tooltip a {
    color: white; }
  .tooltip .tooltip-inner {
    padding: 20px;
    background-color: #333;
    font-size: 14px;
    max-width: 600px; }
    .tooltip .tooltip-inner h2 {
      font-size: 24px;
      font-weight: bold; }
  .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #333; }
  .tooltip.top .tooltip-arrow {
    border-top-color: #333; }
  .tooltip.in {
    opacity: 1; }

.help-tooltip-trigger {
  cursor: pointer;
  color: #b3b3b3;
  transition: color .2s ease; }
  .help-tooltip-trigger + .tooltip .tooltip-inner {
    text-align: left; }
  .help-tooltip-trigger:hover {
    color: #333; }

.media-grid {
  display: -webkit-box !important;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; }
  .media-grid .media-link {
    border: 0;
    padding: 4%;
    width: 25%; }
    .media-grid .media-link img {
      width: 100%;
      height: auto; }
    @media screen and (min-width: 430px) {
      .media-grid .media-link {
        filter: grayscale(100%);
        opacity: 0.7;
        backface-visibility: hidden;
        transition: all 0.2s ease-in-out; }
        .media-grid .media-link:hover, .media-grid .media-link:focus {
          opacity: 1;
          text-decoration: none;
          border: 0;
          transform: scale(1.1);
          filter: none; } }

.social-link {
  opacity: 0.7;
  border: 0;
  padding: 16px;
  backface-visibility: hidden;
  transition: opacity 0.2s ease, transform 0.2s ease; }
  .social-link:hover, .social-link:focus {
    opacity: 1;
    text-decoration: none;
    border: 0;
    transform: scale(1.1); }
  .social-link img {
    width: 18px;
    height: 18px; }

.optimized-hidden {
  opacity: 0;
  pointer-events: none; }

div.notice {
  font-size: 14px;
  color: #828b90;
  padding: 12px;
  margin-bottom: 12px;
  background: #fafafa; }
  div.notice img {
    width: 18px;
    height: 18px;
    filter: grayscale(100%); }
  div.notice .glyphicon {
    vertical-align: middle; }

#map {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  /*margin-top: 50px;*/
  transition: opacity 0.5s cubic-bezier(0.39, 0.58, 0.57, 1); }
  #map.hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; }

#bottom-navbar {
  display: none; }

.modal {
  -webkit-overflow-scrolling: auto !important;
  overflow-y: auto !important;
  bottom: unset;
  position: absolute; }

.obfuscate {
  unicode-bidi: bidi-override;
  direction: rtl;
  user-select: none;
  cursor: default; }

a:focus, a:active, a:hover {
  text-decoration: none; }

.external-link,
.link-like {
  color: #333;
  box-shadow: inset 0 -4px 0 -1px #F6C913;
  cursor: pointer;
  transition: all 0.2s ease; }
  .external-link:focus, .external-link:active, .external-link:hover,
  .link-like:focus,
  .link-like:active,
  .link-like:hover {
    color: #333;
    text-decoration: none;
    box-shadow: inset 0 -23px 0 -1px #F6C913; }

#map a,
a.unstyled-link {
  border-bottom: inherit;
  box-shadow: none;
  color: inherit;
  transition: inherit; }
  #map a:focus, #map a:active, #map a:hover,
  a.unstyled-link:focus,
  a.unstyled-link:active,
  a.unstyled-link:hover {
    background: inherit; }

.link-button {
  border: none;
  background: transparent;
  min-width: 42px; }
  .link-button:focus, .link-button:active, .link-button:hover {
    text-decoration: underline; }
  .link-button.classic-link {
    color: inherit;
    text-decoration: underline;
    padding: 0; }

.dropdown a,
.dropdown a:hover {
  color: inherit;
  border: inherit; }

.icon-mail {
  height: 14px; }

.svg-icon {
  height: 1em;
  max-width: 1em;
  vertical-align: sub; }

.dropdown-menu {
  padding: 6px 0;
  border: 1px solid #e6e6e6;
  min-width: 180px;
  width: auto;
  animation: slideIn 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }
  .dropdown-menu li {
    margin: 6px 0;
    white-space: nowrap; }
    .dropdown-menu li img {
      vertical-align: sub;
      margin-right: 3px;
      width: 25px;
      max-width: none;
      height: 15px;
      opacity: .7; }
    .dropdown-menu li button {
      padding: 6px 24px;
      width: 100%;
      text-align: left;
      border: 0;
      background: 0 0;
      border-radius: 0; }
      .dropdown-menu li button:disabled, .dropdown-menu li button.disabled {
        opacity: .5;
        pointer-events: none;
        filter: grayscale(100%); }
      .dropdown-menu li button:hover {
        background: #F3F3F3; }

.avatar {
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

#userBtn {
  position: fixed;
  top: 0;
  right: 12px;
  transition: transform 0.3s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 0.2s ease-in-out; }
  #userBtn.cool-hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; }
  #userBtn > button {
    border: 0;
    padding: 0;
    background: none;
    border-radius: 50%;
    padding-right: 12px; }
  #userBtn.admin .avatar {
    border: 2px solid gold;
    box-shadow: 0px 2px 20px gold; }
  #userBtn .avatar {
    width: 40px;
    height: 40px;
    margin: 10px 6px 10px; }
  #userBtn .dropdown-menu:before {
    position: absolute;
    top: -10px;
    right: 14px;
    display: inline-block;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e6e6e6;
    border-left: 10px solid transparent;
    border-bottom-color: #e6e6e6;
    content: ''; }
  #userBtn .dropdown-menu:after {
    position: absolute;
    top: -8px;
    right: 14px;
    display: inline-block;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    content: ''; }
  #userBtn .dropdown-menu img {
    height: 14px; }

#logo {
  position: fixed;
  margin: 0;
  left: 24px;
  top: 6px; }
  #logo img {
    width: 130px; }
  #logo span {
    font-size: 18px;
    font-weight: 700;
    color: #30bb6a;
    vertical-align: middle; }

#locationQueryBtn {
  display: none; }

#locationSearch {
  top: 9px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 95%;
  background: white;
  max-width: 400px;
  width: calc(100% - 20px);
  transition: transform 0.3s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 0.2s ease-in-out; }
  #locationSearch.cool-hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; }
  #locationSearch #geolocationQuery {
    display: none; }
  #locationSearch .search-fields {
    width: 100%;
    font-size: 16px;
    padding: 0 12px 0 40px;
    height: 42px;
    border: none; }
    #locationSearch .search-fields::-webkit-input-placeholder {
      color: #b3b3b3; }
  #locationSearch #clearLocationQueryBtn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: transparent;
    font-size: 20px;
    margin: 0;
    padding: 6px 13px;
    height: 42px;
    transition: all 0.2s ease;
    pointer-events: none; }
    #locationSearch #clearLocationQueryBtn .search-icon {
      opacity: 1;
      visibility: visible;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); }
    #locationSearch #clearLocationQueryBtn .clear-icon {
      opacity: 0;
      visibility: hidden; }
    #locationSearch #clearLocationQueryBtn.clear-mode {
      pointer-events: auto; }
      #locationSearch #clearLocationQueryBtn.clear-mode .search-icon {
        opacity: 0;
        visibility: hidden; }
      #locationSearch #clearLocationQueryBtn.clear-mode .clear-icon {
        opacity: 1;
        visibility: visible;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 10px; }
  @media screen and (min-width: 430px) {
    #locationSearch #clearLocationQueryBtn {
      right: 0; } }

@media screen and (min-width: 430px) {
  .mobile-only {
    display: none; }
  #userBtn {
    display: none; }
    #userBtn .avatar {
      box-shadow: none; }
  #locationSearch {
    margin-left: 170px; }
    #locationSearch #locationQueryInput {
      box-shadow: none !important;
      background: #fafafa; }
  .hamburger-button {
    display: none; }
  .navbar-de-boa {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: white;
    height: 60px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 0.2s ease-in-out; }
    .navbar-de-boa.cool-hidden {
      opacity: 0;
      transform: translateY(-20px);
      pointer-events: none; }
    .navbar-de-boa .navbar-de-boa--list {
      display: flex;
      list-style: none;
      height: 100%;
      justify-content: flex-end;
      margin-right: 12px; }
      .navbar-de-boa .navbar-de-boa--list .caret {
        color: #b3b3b3; }
      .navbar-de-boa .navbar-de-boa--list .navbar-de-boa--item {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: 0 12px;
        cursor: pointer;
        transition: color 0.2s ease; }
        .navbar-de-boa .navbar-de-boa--list .navbar-de-boa--item a {
          color: #333; }
        .navbar-de-boa .navbar-de-boa--list .navbar-de-boa--item:hover > a {
          color: #30bb6a; }
        .navbar-de-boa .navbar-de-boa--list .navbar-de-boa--item:hover > ul.dropdown-menu {
          display: block; }
    .navbar-de-boa .dropdown-menu {
      top: 58px;
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: auto; } }

.nav-pills > li a {
  color: #30bb6a;
  text-align: center;
  padding: 12px;
  border-radius: 0;
  border-bottom: 2px solid #F3F3F3; }

.nav-pills > li .badge {
  background-color: #30bb6a;
  color: white; }

.nav-pills .nav-pills > li + li {
  margin: 0; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #30bb6a;
  border-bottom: 2px solid #30bb6a;
  background: transparent; }
  .nav-pills > li.active > a .badge,
  .nav-pills > li.active > a:focus .badge,
  .nav-pills > li.active > a:hover .badge {
    background: #30bb6a;
    color: white; }

.modal {
  overflow-x: hidden;
  overflow-y: auto; }
  .modal:not(.fullscreen-modal) {
    cursor: zoom-out; }
  .modal .modal-dialog {
    margin-top: 24px;
    cursor: auto; }
  .modal .modal-body {
    padding: 36px 36px; }
    .modal .modal-body hr {
      margin-top: 10px;
      margin-bottom: 10px; }
    .modal .modal-body .typeIcon {
      width: 32%;
      height: auto;
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      border-radius: 15px;
      padding: 12px 3%;
      position: relative;
      opacity: 0.9;
      text-align: center; }
      .modal .modal-body .typeIcon .icon-label {
        text-align: center;
        margin-top: 12px;
        color: #828b90;
        font-weight: 700;
        user-select: none;
        margin-bottom: 2px;
        transition: all 0.3s ease;
        margin-top: calc(12px - 2px);
        margin-bottom: 0;
        border: 2px solid transparent;
        border-radius: 25px; }
      .modal .modal-body .typeIcon img {
        width: 100%;
        height: 100%;
        max-width: 100px; }
      .modal .modal-body .typeIcon.active {
        filter: none;
        opacity: 1; }
        .modal .modal-body .typeIcon.active .icon-label {
          color: #30bb6a;
          border-color: #30bb6a; }
      .modal .modal-body .typeIcon:hover {
        background-color: #fafafa;
        opacity: 1; }
  .modal.fullscreen-modal .modal-content {
    box-shadow: none;
    background: transparent; }
  .modal.fullscreen-modal article {
    margin-left: -36px;
    margin-right: -36px; }

#guideTagsModal h3 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1em; }

#guideTagsModal .guide-type-img {
  width: 150px;
  padding: 0; }

#guideTagsModal section {
  margin-top: 2em; }

.guide-modal .close-and-filter {
  color: #30bb6a;
  padding: 0;
  margin: .5em 0;
  font-size: 16px; }

.guide-modal .guide-img-row {
  margin-top: 3em;
  display: flex;
  justify-content: center; }
  .guide-modal .guide-img-row > a:first-child img {
    margin-left: 0; }
  .guide-modal .guide-img-row > a:last-child img {
    margin-right: 0; }
  .guide-modal .guide-img-row img {
    opacity: .8;
    width: 200px;
    height: 200px;
    margin: 0 6px;
    object-fit: cover;
    background: #e6e6e6;
    max-width: none; }
    .guide-modal .guide-img-row img:hover {
      opacity: 1; }

.guide-modal .guide-type-img {
  width: 200px;
  padding: 24px;
  padding-top: 0; }

.guide-modal .center {
  text-align: center; }

.guide-modal .guide-references-list {
  list-style: none;
  padding: 0;
  margin-top: -.5em; }
  .guide-modal .guide-references-list li {
    margin-bottom: 0.5em; }
    .guide-modal .guide-references-list li .glyphicon-share {
      font-size: 12px; }
    .guide-modal .guide-references-list li .references-label {
      display: block;
      line-height: 16px;
      font-style: italic;
      color: #828b90; }

.guide-modal section {
  margin-top: 6em; }

.big-numbers {
  display: flex !important;
  justify-content: space-around;
  flex-wrap: wrap; }
  .big-numbers .big-numbers--col {
    text-align: center;
    padding: 12px; }
  .big-numbers .big-numbers--number {
    font-size: 54px;
    line-height: 54px;
    font-weight: bold; }
  .big-numbers .big-numbers--label {
    font-size: 16px; }

#contributionsModal h2 {
  text-align: center;
  margin: 0; }
  #contributionsModal h2 img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 6px;
    display: block; }
    #contributionsModal h2 img.admin {
      border: 2px solid #F6C913;
      box-shadow: 0 2px 20px #F6C913; }

#contributionsModal .you-are-admin {
  text-align: center;
  color: #F6C913;
  margin: 12px 0; }

#contributionsModal .nav-pills {
  display: flex;
  margin-top: 24px; }
  #contributionsModal .nav-pills > li {
    flex: 1;
    text-align: center; }

#contributionsModal .tab-content {
  margin-top: 6px; }
  #contributionsModal .tab-content li {
    border-bottom: 1px solid #F3F3F3; }

#contributionsModal h3 {
  padding: 12px 12px 12px 0;
  font-size: 12px;
  font-weight: bold;
  color: #828b90;
  user-select: none;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 0; }

#contributionsModal ul {
  list-style: none;
  padding: 0; }

#contributionsModal .big-numbers {
  margin: 2em 0 1em; }

#contributionsModal .go-to-place-btn {
  border: 0;
  padding: 12px 0;
  background: transparent;
  color: #333;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center; }
  #contributionsModal .go-to-place-btn:hover, #contributionsModal .go-to-place-btn:active, #contributionsModal .go-to-place-btn:focus {
    background: #F3F3F3; }
  #contributionsModal .go-to-place-btn > span {
    flex: 1; }
  #contributionsModal .go-to-place-btn .go-to-place-btn--photo {
    width: 40px;
    height: 40px;
    flex: 0 1 40px;
    margin-right: 12px; }
    #contributionsModal .go-to-place-btn .go-to-place-btn--photo img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  #contributionsModal .go-to-place-btn .go-to-place-btn--title {
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    letter-spacing: -0.01em;
    line-height: normal;
    margin: 0; }
  #contributionsModal .go-to-place-btn .go-to-place-btn--rating {
    font-weight: bold;
    display: block;
    margin-top: 2px;
    font-size: 14px; }
  #contributionsModal .go-to-place-btn .go-to-place-btn--daysago {
    text-align: right; }
  #contributionsModal .go-to-place-btn .go-to-place-btn--daysago,
  #contributionsModal .go-to-place-btn .go-to-place-btn--views {
    font-size: 12px;
    color: #b3b3b3; }

#dataModal h3 {
  font-size: 18px;
  margin: 1.5em 0 .5em;
  font-weight: bold; }

#dataModal .data-modal--block {
  display: flex !important;
  align-items: center;
  margin: 2em 0;
  background: #30bb6a;
  color: white;
  border-radius: 5px;
  padding: 12px 24px; }
  #dataModal .data-modal--block:active, #dataModal .data-modal--block:focus, #dataModal .data-modal--block:hover {
    background: #2cd978; }
  #dataModal .data-modal--block .data-modal--block-icon {
    padding-right: 24px; }
    #dataModal .data-modal--block .data-modal--block-icon img {
      width: 64px; }
  #dataModal .data-modal--block .data-modal--block-content .data-modal--block-title {
    font-size: 24px;
    font-weight: 700; }
  #dataModal .data-modal--block .data-modal--block-content p {
    font-size: 14px;
    line-height: 1.3em; }
  #dataModal .data-modal--block .data-modal--block-arrow {
    font-size: 20px;
    padding-left: 24px; }

.illustration {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 3em; }

#aboutModal #about-social-buttons,
#privacyModal #about-social-buttons,
#dataModal #about-social-buttons,
.guide-modal #about-social-buttons {
  display: flex !important;
  justify-content: space-around; }

#aboutModal .about-collaborators,
#privacyModal .about-collaborators,
#dataModal .about-collaborators,
.guide-modal .about-collaborators {
  font-size: 16px;
  background: #F3F3F3;
  padding: 24px;
  margin: auto;
  border-radius: 5px; }
  #aboutModal .about-collaborators ul,
  #privacyModal .about-collaborators ul,
  #dataModal .about-collaborators ul,
  .guide-modal .about-collaborators ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    #aboutModal .about-collaborators ul li:first-child,
    #privacyModal .about-collaborators ul li:first-child,
    #dataModal .about-collaborators ul li:first-child,
    .guide-modal .about-collaborators ul li:first-child {
      margin-bottom: 1em;
      font-weight: bold; }
    #aboutModal .about-collaborators ul li,
    #privacyModal .about-collaborators ul li,
    #dataModal .about-collaborators ul li,
    .guide-modal .about-collaborators ul li {
      line-height: 1em; }
    #aboutModal .about-collaborators ul li + li,
    #privacyModal .about-collaborators ul li + li,
    #dataModal .about-collaborators ul li + li,
    .guide-modal .about-collaborators ul li + li {
      margin-top: .5em; }

#aboutModal .big-numbers,
#privacyModal .big-numbers,
#dataModal .big-numbers,
.guide-modal .big-numbers {
  margin: 2em 0; }

#aboutModal .social-link,
#privacyModal .social-link,
#dataModal .social-link,
.guide-modal .social-link {
  color: #333;
  font-size: 18px;
  text-align: center; }
  #aboutModal .social-link img,
  #privacyModal .social-link img,
  #dataModal .social-link img,
  .guide-modal .social-link img {
    width: 28px;
    height: 28px;
    vertical-align: bottom; }

#aboutModal p.subtitle,
#privacyModal p.subtitle,
#dataModal p.subtitle,
.guide-modal p.subtitle {
  font-size: 24px;
  margin-bottom: 2em; }

#aboutModal h1,
#privacyModal h1,
#dataModal h1,
.guide-modal h1 {
  font-size: 54px;
  margin-top: 1.5em;
  margin-bottom: .5em;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  font-weight: bold; }

#aboutModal h2,
#privacyModal h2,
#dataModal h2,
.guide-modal h2 {
  color: #30bb6a;
  font-weight: bold;
  font-size: 36px;
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: bold; }

#aboutModal .guide--updated-at,
#privacyModal .guide--updated-at,
#dataModal .guide--updated-at,
.guide-modal .guide--updated-at {
  text-transform: uppercase;
  color: #828b90;
  font-size: 12px; }

#aboutModal .guide--pretitle,
#privacyModal .guide--pretitle,
#dataModal .guide--pretitle,
.guide-modal .guide--pretitle {
  font-size: 24px;
  margin-bottom: .25em;
  letter-spacing: 0;
  line-height: 1.2em;
  font-weight: bold; }
  #aboutModal .guide--pretitle img,
  #privacyModal .guide--pretitle img,
  #dataModal .guide--pretitle img,
  .guide-modal .guide--pretitle img {
    height: 18px;
    vertical-align: baseline; }

#aboutModal .medium-separator,
#privacyModal .medium-separator,
#dataModal .medium-separator,
.guide-modal .medium-separator {
  margin: 2em 0;
  text-align: center;
  letter-spacing: 1em;
  font-weight: bold;
  font-size: 20px;
  user-select: none; }

#howToInstallModal .browser-icon {
  width: 32px;
  height: 32px; }

#howToInstallModal .browser-icon + span {
  vertical-align: middle; }

.panel-group {
  margin-bottom: 24px; }
  .panel-group .panel {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1em; }
    .panel-group .panel .panel-heading {
      padding: 0; }
      .panel-group .panel .panel-heading a .panel-title:before {
        position: relative;
        float: right;
        font-family: "Glyphicons Halflings";
        font-weight: 400;
        color: #30bb6a;
        line-height: 1;
        content: "\e260"; }
      .panel-group .panel .panel-heading a.collapsed .panel-title:before {
        content: "\e259"; }
    .panel-group .panel .panel-title {
      font-family: "Quicksand", sans-serif;
      color: #333;
      padding: 1em;
      transition: background 0.2s ease-out; }
      .panel-group .panel .panel-title:hover {
        background: #fafafa; }
    .panel-group .panel .panel-body {
      border-top: 2px solid #e6e6e6;
      background: #fafafa;
      padding: 12px 12px 24px; }
      .panel-group .panel .panel-body p {
        font-size: 14px; }

.tutorial-gif {
  max-width: 260px;
  height: auto;
  margin: 24px auto 48px auto;
  display: block;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
  min-height: 460px;
  background: #b3b3b3; }

#geolocationBtn {
  position: fixed;
  bottom: 76px;
  right: 0px;
  margin: 0px 12px 12px;
  padding: 13px;
  background-color: white;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  filter: grayscale(100%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  #geolocationBtn.active {
    filter: none; }
  #geolocationBtn img {
    width: 20px; }

#addPlace {
  position: fixed;
  bottom: 14px;
  right: 0px;
  margin: 0 12px 12px;
  background: #FFF;
  border: 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  white-space: nowrap;
  padding: 13px;
  text-align: left;
  overflow: hidden;
  transition: width 0.5s cubic-bezier(0.39, 0.58, 0.57, 1), background 0.2s; }
  #addPlace img {
    width: 22px;
    margin-left: 2px; }
  #addPlace.expanded {
    width: 220px; }
  #addPlace span {
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    vertical-align: sub; }
  #addPlace.active {
    background: #333;
    transition: all 0.3s cubic-bezier(0.39, 0.58, 0.57, 1); }
    #addPlace.active img {
      display: none; }
    #addPlace.active:after {
      content: '✕';
      color: white;
      font-size: 18px;
      padding: 4px; }

#filterBtn {
  position: fixed;
  top: 80px;
  right: 0px;
  border-radius: 25px;
  border: 0;
  background-color: white;
  font-family: "Quicksand", sans-serif;
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0px 12px 12px; }
  #filterBtn.active {
    border: 2px solid #9688DC;
    background-color: #fafafa; }
    #filterBtn.active img {
      margin-left: -2px; }
  #filterBtn img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin: 0; }
  #filterBtn #active-filters-counter.badge {
    background-color: #705EC7; }
  #filterBtn .badge {
    position: absolute;
    bottom: 0;
    right: 0; }

#mmapBtn {
  position: fixed;
  bottom: 138px;
  right: 0px;
  border-radius: 25px;
  border: 0;
  background-color: white;
  font-family: "Quicksand", sans-serif;
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0px 12px 12px; }
  #mmapBtn.active {
    border: 2px solid #9688DC;
    background-color: #fafafa; }
    #mmapBtn.active img {
      margin-left: -2px; }
  #mmapBtn img {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    margin: 0; }
  #mmapBtn #active-filters-counter.badge {
    background-color: #705EC7; }
  #mmapBtn .badge {
    position: absolute;
    bottom: 0;
    right: 0; }

.navbar-header {
  text-align: center;
  padding-top: 14px;
  font-size: 18px;
  color: white;
  font-weight: bold; }

.bigRoundedButton {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin: auto;
  z-index: 1;
  background: #30bb6a;
  background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%);
  border: none;
  color: white;
  border-radius: 25px;
  width: 70%;
  height: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  min-width: 250px;
  max-width: 300px;
  transition: all 0.5s ease; }

.bigRoundedButton:hover,
.bigRoundedButton:active,
.bigRoundedButton:focus {
  color: white;
  background-color: #2cd978; }

#newPlaceholderContainer #newPlaceholderConfirmBtn {
  display: none;
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  font-size: 18px;
  padding: 12px 24px;
  background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); }

#newPlaceholderContainer #newPlaceholder {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 90px;
  height: auto;
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  filter: grayscale(100%);
  transition: top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  #newPlaceholderContainer #newPlaceholder.active {
    top: 50%;
    opacity: 1;
    animation: bounceAndGlow 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate; }
  #newPlaceholderContainer #newPlaceholder.invalid {
    animation: none;
    filter: grayscale(1); }

#newPlaceholderContainer #newPlaceholderTarget {
  position: absolute;
  top: calc(50% - 3px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 30px;
  height: auto;
  display: none;
  pointer-events: none;
  animation: pulseSubtle 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate; }

@keyframes pulseSubtle {
  from {
    opacity: 1; }
  to {
    opacity: .8; } }

.modal-backdrop {
  background-color: #fafafa; }

.modal-backdrop.in {
  opacity: 0.8; }

#spinnerOverlay {
  background-color: rgba(250, 250, 250, 0.8);
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none; }
  #spinnerOverlay .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
    #spinnerOverlay .spinnerContainer img {
      animation: spin 3s linear infinite;
      width: 72px;
      height: 72px; }
    #spinnerOverlay .spinnerContainer p {
      color: #828b90;
      font-family: "Quicksand", sans-serif;
      margin-top: 10px;
      font-weight: 700; }
  #spinnerOverlay progress[value] {
    appearance: none;
    border: none;
    display: block;
    height: 3px;
    width: 100px;
    margin: 16px auto; }
    #spinnerOverlay progress[value]::-webkit-progress-bar {
      background-color: #e6e6e6; }
    #spinnerOverlay progress[value]::-webkit-progress-value {
      background: #30bb6a;
      border-radius: 3px;
      transition: all .5s ease-in-out; }

.loading-skeleton {
  animation: pulse 0.5s infinite alternate; }

.modal-header {
  background: #2cd978;
  color: white;
  padding: 0; }

.modal-header {
  background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%); }

.modal-header {
  height: auto; }
  .modal-header .close-modal {
    display: none; }
  .modal-header .modal-title {
    font-family: "Quicksand", sans-serif;
    padding: 36px 36px 12px;
    font-weight: 700;
    letter-spacing: -0.01em; }

#bottom-mobile-bar,
#top-mobile-bar {
  display: none; }

body.search-mode #userBtn,
body.search-mode #search-button {
  display: none; }

body.search-mode #locationSearch {
  box-shadow: none; }

#close-modal-btn {
  display: none; }

body.modal-open {
  overflow-y: scroll; }
  body.modal-open #close-modal-btn {
    display: block;
    position: fixed;
    color: #333;
    top: 48px;
    left: calc(50% + 300px + 23px);
    font-size: 30px;
    line-height: 22px;
    border: 0;
    background: none;
    z-index: 1052;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    transition: all 0.2s ease-out; }
    body.modal-open #close-modal-btn:hover {
      background: #e6e6e6; }

body.fullscreen-modal-open .modal-backdrop.in {
  opacity: 1;
  background: white; }

body.fullscreen-modal-open .navbar-de-boa,
body.fullscreen-modal-open #userBtn,
body.fullscreen-modal-open #logo {
  z-index: 1051; }

body.fullscreen-modal-open #close-modal-btn {
  top: calc(60px + 12px*2);
  right: 24px;
  left: auto; }

.modal-content {
  border: 0;
  border-radius: 5px;
  margin-top: 24px;
  margin-bottom: 100px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15); }
  .modal-content .modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .modal-content label {
    font-weight: initial; }
  .modal-content .address {
    font-size: 14px;
    line-height: 16px;
    color: #828b90;
    margin-top: 0px;
    /* margin-left: 40px; */ }
    .modal-content .address .skeleton-box {
      width: 100%;
      height: 30px; }
  .modal-content .title {
    vertical-align: middle;
    font-family: "Quicksand", sans-serif; }
    .modal-content .title h1 {
      font-weight: 700;
      font-size: 30px;
      margin-top: 5px;
      line-height: 1.2em;
      letter-spacing: -0.01em; }
    .modal-content .title h2 {
      font-size: 18px;
      font-weight: normal;
      margin-top: 24px;
      margin-bottom: 0;
      color: #666; }
      .modal-content .title h2::first-letter {
        text-transform: uppercase; }
    .modal-content .title > .skeleton-box {
      width: 70%;
      height: 40px;
      background: rgba(0, 0, 0, 0.2); }
  .modal-content article p {
    font-size: 16px;
    line-height: 1.6em; }
  .modal-content .text-input-wrapper {
    position: relative;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 20px; }
  .modal-content .text-input {
    padding: 12px;
    width: 100%; }
  .modal-content #titleInput {
    font-weight: 700;
    letter-spacing: -0.01em;
    border: 3px solid #e6e6e6;
    border-radius: 5px; }
    .modal-content #titleInput::-webkit-input-placeholder {
      color: #b3b3b3; }
  .modal-content .tagsContainer {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2em 0; }
    .modal-content .tagsContainer h2 {
      font-family: "Quicksand", sans-serif;
      margin: 10px 0; }
  .modal-content section.rating-input-container {
    text-align: center;
    padding: 48px 0;
    background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%); }
    .modal-content section.rating-input-container h2 {
      margin: 0;
      font-family: "Quicksand", sans-serif;
      color: white;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: .5em; }

.photo-container {
  padding: 0px;
  position: relative;
  background: #F3F3F3;
  height: 160px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden; }
  .photo-container img[src=""] {
    display: none; }
  .photo-container.skeleton-box {
    display: block;
    height: 170px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .photo-container.hasImage {
    max-height: 60vh;
    height: auto;
    min-height: 300px; }
    .photo-container.hasImage img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 66%; }
  .photo-container.hasImage:not(.loading) img {
    cursor: zoom-in; }
    .photo-container.hasImage:not(.loading) img:hover {
      opacity: 0.8; }

.photo-input--container {
  height: 180px;
  background: none; }
  .photo-input--container .photoInputBg,
  .photo-input--container #photoInputBg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none; }
  .photo-input--container .photo-input--label {
    border: 3px dotted #30bb6a;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative; }
    .photo-input--container .photo-input--label:hover, .photo-input--container .photo-input--label:focus, .photo-input--container .photo-input--label:active {
      background: rgba(0, 0, 0, 0.03); }
    .photo-input--container .photo-input--label.photo-input--edit-mode {
      border: none;
      background: rgba(0, 0, 0, 0.1);
      opacity: .9; }
      .photo-input--container .photo-input--label.photo-input--edit-mode .photo-input--calltoaction {
        color: white; }
      .photo-input--container .photo-input--label.photo-input--edit-mode:hover, .photo-input--container .photo-input--label.photo-input--edit-mode:focus, .photo-input--container .photo-input--label.photo-input--edit-mode:active {
        opacity: 1;
        background: rgba(0, 0, 0, 0.2); }
    .photo-input--container .photo-input--label .photo-input--calltoaction {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #30bb6a; }
      .photo-input--container .photo-input--label .photo-input--calltoaction img {
        width: 40px; }
  .photo-input--container .photo-input {
    opacity: 0;
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: -999px;
    left: -999px; }

#newRequestModal .photo-input--container .photo-input--label {
  border-color: #56CCf2; }

#newRequestModal .photo-input--calltoaction {
  color: #56CCf2; }

#newRequestModal .saveNewPlaceBtn {
  background-color: #56CCf2; }

#newPlaceModal section, .place-form section {
  margin: 12px 0 24px; }
  #newPlaceModal section h2, .place-form section h2 {
    padding-top: 12px;
    font-size: 16px;
    user-select: none;
    font-weight: bold; }

#newPlaceModal section + section, .place-form section + section {
  border-top: 2px dotted #e6e6e6; }

#newPlaceModal .covered-group,
#newPlaceModal .acess-types-group, .place-form .covered-group,
.place-form .acess-types-group {
  text-align: center; }
  #newPlaceModal .covered-group .typeIcon,
  #newPlaceModal .acess-types-group .typeIcon, .place-form .covered-group .typeIcon,
  .place-form .acess-types-group .typeIcon {
    width: 49%;
    padding-left: 12%;
    padding-right: 12%; }

#newPlaceModal .photo-container:before, .place-form .photo-container:before {
  display: none; }

#newPlaceModal .photo-container > img, .place-form .photo-container > img {
  object-fit: contain;
  width: 100%;
  height: 100%; }

#newPlaceModal .minimap-container, .place-form .minimap-container {
  position: relative; }
  #newPlaceModal .minimap-container #editPlacePositionBtn, .place-form .minimap-container #editPlacePositionBtn {
    position: absolute;
    top: 20px;
    right: 0;
    color: #30bb6a;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.2s ease-out; }
  #newPlaceModal .minimap-container .minimap, .place-form .minimap-container .minimap {
    width: 100%; }

#newPlaceModal .stickyFooterBtn, .place-form .stickyFooterBtn {
  padding: 12px 0;
  text-align: center;
  position: sticky;
  bottom: 0;
  background: white; }

#newPlaceModal .extraInfo, .place-form .extraInfo {
  padding-top: 0; }
  #newPlaceModal .extraInfo label, .place-form .extraInfo label {
    display: block;
    font-weight: bold; }
  #newPlaceModal .extraInfo textarea, .place-form .extraInfo textarea {
    resize: none; }
  #newPlaceModal .extraInfo select, .place-form .extraInfo select {
    text-indent: 6px;
    background: white; }
  #newPlaceModal .extraInfo textarea, #newPlaceModal .extraInfo input, #newPlaceModal .extraInfo select, .place-form .extraInfo textarea, .place-form .extraInfo input, .place-form .extraInfo select {
    margin: 0 0 12px 0;
    border: 3px solid #e6e6e6;
    border-radius: 5px;
    width: 100%; }
  #newPlaceModal .extraInfo input[type='number'], .place-form .extraInfo input[type='number'] {
    padding: 6px 12px; }
  #newPlaceModal .extraInfo p, .place-form .extraInfo p {
    margin: 24px 0 0; }
  #newPlaceModal .extraInfo.collapsable, .place-form .extraInfo.collapsable {
    margin: 0;
    max-height: 60px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.5s ease-in-out; }
    #newPlaceModal .extraInfo.collapsable h2, .place-form .extraInfo.collapsable h2 {
      padding-left: 0;
      color: #333;
      text-align: left; }
      #newPlaceModal .extraInfo.collapsable h2:after, .place-form .extraInfo.collapsable h2:after {
        font-family: "Glyphicons Halflings";
        content: '\e252';
        font-size: 14px; }
    #newPlaceModal .extraInfo.collapsable:not(.expanded), .place-form .extraInfo.collapsable:not(.expanded) {
      cursor: pointer; }
      #newPlaceModal .extraInfo.collapsable:not(.expanded):hover, .place-form .extraInfo.collapsable:not(.expanded):hover {
        background: #e6e6e6; }
  #newPlaceModal .extraInfo.expanded, .place-form .extraInfo.expanded {
    max-height: 500px; }
    #newPlaceModal .extraInfo.expanded h2:after, .place-form .extraInfo.expanded h2:after {
      content: ''; }

#newRequestOrPlaceModal button {
  border-radius: 5px;
  border-color: #E0E0E0; }
  #newRequestOrPlaceModal button h3, #newRequestOrPlaceModal button p {
    text-align: left;
    float: right;
    width: calc(100% - 120px); }
  #newRequestOrPlaceModal button h3 {
    font-weight: bold; }
  #newRequestOrPlaceModal button p {
    font-size: 18px;
    color: #4F4F4F; }
  #newRequestOrPlaceModal button:hover {
    background: #F2F2F2; }
  #newRequestOrPlaceModal button:first-of-type {
    margin: 0px 0px 40px 0; }
  #newRequestOrPlaceModal button img {
    float: left;
    width: 80px;
    display: inline-block;
    margin: 25px 10px 0px 20px; }

.review {
  text-align: center; }
  .review .rating-display-container {
    margin: 1em 0; }

#login-container span {
  font-size: 16px; }

#login-container img {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  opacity: 1; }

.average {
  font-size: 24px;
  vertical-align: middle;
  font-weight: 700;
  color: #fff;
  border-radius: 25px;
  padding: 3px 10px;
  background-color: #828b90; }

.ghost {
  padding: 5px 10px;
  border: 2px solid #30bb6a;
  color: #30bb6a;
  margin: 5px auto;
  font-size: 16px;
  border-radius: 25px;
  vertical-align: middle;
  background: transparent;
  transition: all .2s; }
  .ghost:hover, .ghost:focus, .ghost:active {
    color: white;
    border-color: transparent;
    background-color: #2cd978; }

.support-btn {
  padding: 6px 34px;
  border: 2px solid #2F80ED;
  color: #2F80ED;
  margin: 5px auto;
  font-size: 16px;
  border-radius: 25px;
  vertical-align: middle;
  background: transparent;
  transition: all .2s; }
  .support-btn:hover, .support-btn:focus, .support-btn:active, .support-btn.active {
    color: white;
    background-color: #2D9CDB; }
  .support-btn:disabled {
    color: white;
    background-color: #b3b3b3;
    border-color: #828b90; }
  .support-btn.active:hover, .support-btn.active:focus, .support-btn.active:active {
    color: #2F80ED;
    background-color: transparent; }

.modal-footer {
  text-align: left;
  border: none;
  padding: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden; }
  .modal-footer .btn + .btn {
    text-transform: uppercase; }

.support-area {
  text-align: center; }
  .support-area p {
    font-size: 18px;
    font-weight: 700; }
  .support-area.disabled {
    opacity: 0.5; }

.info {
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin-top: 24px; }
  .info > div {
    width: 30%; }
  .info.request-description {
    text-align: left; }
  .info img {
    margin: 0 5px;
    display: inline-block;
    max-width: 100px;
    height: auto;
    filter: grayscale(100%) brightness(0.8); }
  .info .skeleton-box {
    height: 90px;
    background: rgba(0, 0, 0, 0.05); }
  .info small {
    color: #828b90; }
  .info .missing-info-container {
    opacity: .8;
    display: flex;
    align-items: center;
    justify-content: center; }
    .info .missing-info-container .missing-info-placeholder {
      font-size: 100px;
      line-height: 100px;
      color: #828b90; }
    .info .missing-info-container button {
      font-weight: bold;
      color: #30bb6a; }
    .info .missing-info-container .icon-label {
      font-weight: 100;
      color: #b3b3b3; }
  .info .icon-label {
    margin-top: 10px;
    color: #828b90;
    font-weight: 700;
    user-select: none;
    cursor: default; }
    .info .icon-label small {
      font-weight: 400; }
    .info .icon-label.skeleton-box {
      height: 20px;
      background: rgba(0, 0, 0, 0.05); }

#placeDetailsContent .review .savedRating {
  text-align: center;
  color: #828b90;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  background: #fafafa;
  padding: 12px;
  vertical-align: middle; }
  #placeDetailsContent .review .savedRating button {
    font-size: 12px;
    margin-left: 6px; }
  #placeDetailsContent .review .savedRating img {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 3px; }

#placeDetailsContent .review .numreviews {
  margin: 12px 0 0 2px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none; }

#placeDetailsContent .review .numreviews + .openReviewPanelBtn {
  margin-top: 12px;
  font-size: 16px; }

#placeDetailsContent .modal-body {
  padding-top: 12px; }
  #placeDetailsContent .modal-body .place-warning {
    background: #FFE064;
    margin-left: -36px;
    margin-right: -36px;
    background-repeat: no-repeat;
    background-size: 42px;
    background-position-x: 36px;
    background-position-y: 50%;
    display: flex; }
    #placeDetailsContent .modal-body .place-warning.hotspot {
      background-image: url("/img/pin_hotspot.png"); }
    #placeDetailsContent .modal-body .place-warning.biciparque {
      background-image: url("/img/pin_biciparque.png"); }
    #placeDetailsContent .modal-body .place-warning .close-place-warning {
      font-weight: bold; }
      #placeDetailsContent .modal-body .place-warning .close-place-warning a {
        font-size: 24px;
        color: black; }
  #placeDetailsContent .modal-body .access-biciparque {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 20px 30px; }
    #placeDetailsContent .modal-body .access-biciparque button {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      color: green; }
  @media screen and (min-width: 430px) {
    #placeDetailsContent .modal-body .place-warning {
      padding: 24px;
      padding-left: 108px; } }
  #placeDetailsContent .modal-body .request-done {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background: rgba(0, 212, 126, 0.1);
    margin-top: -24px;
    margin-bottom: -24px;
    margin-left: -12px;
    margin-right: -12px;
    background-image: url("/img/request-done.png");
    background-repeat: no-repeat;
    background-size: 44px;
    background-position-x: 12px;
    background-position-y: 50%;
    padding: 36px 48px 24px 72px; }
  @media screen and (min-width: 430px) {
    #placeDetailsContent .modal-body .request-done {
      margin-left: 0;
      margin-right: 0;
      background-position-x: 36px;
      padding: 36px 36px 24px 108px; } }

#placeDetailsContent .tagDisplay {
  display: inline-block;
  width: calc(33% - 12px);
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  cursor: default;
  color: #333; }
  #placeDetailsContent .tagDisplay .tag-count {
    font-weight: 100;
    font-size: 12px; }
  #placeDetailsContent .tagDisplay .tag-icon {
    font-size: 2em;
    opacity: .8; }

#placeDetailsContent .details-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  #placeDetailsContent .details-list li {
    display: flex;
    align-items: center;
    margin-top: 1em; }
  #placeDetailsContent .details-list .details-list-icon {
    font-size: 22px;
    margin: 0 12px;
    color: #828b90;
    min-width: 30px;
    text-align: center; }

#placeDetailsContent hr {
  margin-top: 24px;
  margin-bottom: 24px; }

#placeDetailsContent .minimap-container {
  margin-top: 12px; }
  #placeDetailsContent .minimap-container .minimap {
    min-height: 50px;
    background: #F3F3F3;
    width: calc(100% - 24px);
    margin-left: 12px; }
    #placeDetailsContent .minimap-container .minimap.close-modal {
      cursor: pointer; }

#placeDetailsContent .modal-footer .avatar {
  margin-bottom: 0.5em;
  box-shadow: none; }

#placeDetails_isPublic img {
  opacity: 0.6; }

#ratingDisplay {
  margin: 0 5px; }

#editPlaceBtn {
  right: 70px; }

.btn {
  min-width: 42px;
  min-height: 42px;
  border-radius: 5px;
  border-top: none;
  border-left: none;
  border-right: none; }
  .btn.active {
    box-shadow: none; }

.customLoginBtn {
  display: block;
  margin: 1em auto;
  width: 13em;
  position: relative;
  border: none;
  height: 48px;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  transition: all .2s;
  /* Facebook */
  /* Google */ }
  .customLoginBtn:hover:not([disabled]) {
    transform: translateY(-1px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); }
  .customLoginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 100%; }
  .customLoginBtn:focus {
    outline: none; }
  .customLoginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1); }
  .customLoginBtn.facebookLoginBtn {
    background-color: #4267b2;
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C; }
    .customLoginBtn.facebookLoginBtn:before {
      background: url("/img/icon_social_facebook_w.svg") 12px 12px no-repeat;
      background-size: 24px; }
    .customLoginBtn.facebookLoginBtn:hover, .customLoginBtn.facebookLoginBtn:focus {
      background-color: #5B7BD5; }
  .customLoginBtn.googleLoginBtn {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: white;
    border: 1px solid #828b90;
    color: #333; }
    .customLoginBtn.googleLoginBtn:before {
      background: url("/img/icon_google.png") 12px 12px no-repeat;
      background-size: 24px; }
    .customLoginBtn.googleLoginBtn:hover, .customLoginBtn.googleLoginBtn:focus {
      background: #F3F3F3; }

.post-create-modal .swal2-confirm {
  background: transparent !important;
  color: #2cd978 !important;
  font-weight: 100 !important; }

.support-modal .swal2-cancel,
.support-modal .swal2-confirm {
  width: 100%;
  background-color: #2D9CDB !important; }

.support-modal .support p.small {
  margin-top: -5px;
  margin-bottom: 24px;
  font-size: 13px;
  color: #828b90;
  font-style: italic; }

.support-modal .support #support-attrs {
  display: flex;
  flex-wrap: wrap; }

.support-modal .support .support-attr {
  flex-grow: 1;
  width: 50%; }
  .support-modal .support .support-attr label {
    margin: 5px 5%;
    border-radius: 25px;
    border: 1px solid #2F80ED;
    color: #2F80ED;
    padding: 10px 0;
    width: 90%;
    font-size: 12px; }
    .support-modal .support .support-attr label:hover {
      background-color: lightblue; }
    .support-modal .support .support-attr label.active {
      color: #fff;
      background: #2F80ED; }
  .support-modal .support .support-attr input[type='checkbox'] {
    display: none; }
  .support-modal .support .support-attr:last-child label {
    width: 50%; }

.review-modal {
  text-align: center; }
  .review-modal .tagsContainer {
    max-width: 400px;
    margin: 24px auto 36px;
    text-align: left; }
  .review-modal .swal2-cancel,
  .review-modal .swal2-confirm {
    width: 100%; }
  .review-modal h2 {
    margin-top: 24px;
    margin-bottom: 6px; }
  .review-modal p.small {
    font-size: 13px;
    color: #828b90;
    font-style: italic; }
  .review-modal .tagDisplay {
    background: transparent;
    border: 1px solid #30bb6a;
    color: #30bb6a;
    border-radius: 25px;
    margin: 6px 6px 0 0;
    padding: 6px;
    width: calc(33% - 12px); }
    .review-modal .tagDisplay.active {
      background: #30bb6a;
      color: white;
      box-shadow: none;
      border: 0; }
      .review-modal .tagDisplay.active:hover {
        background: #2cd978; }
    .review-modal .tagDisplay:hover {
      background: #F3F3F3; }

.col-half {
  width: 50%;
  border: none;
  float: left; }

.col-third {
  width: 33%;
  border: none;
  float: left; }

#placeDetailsContent .modal-footer .col-half + .col-half {
  border-left: 1px solid transparent; }

.btn.green {
  background-color: #2cd978;
  color: #fafafa; }
  .btn.green:focus:not([disabled]), .btn.green.active:not([disabled]) {
    color: white;
    background-color: #30bb6a; }

.btn.btn-action {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  transition: all .2s; }
  .btn.btn-action:hover:not([disabled]) {
    transform: translateY(-1px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); }

.btn-footer {
  width: 100%;
  padding: 26px;
  transition: all 0.2s ease;
  border-radius: 0;
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #e6e6e6;
  color: black;
  transition: background-color 0.2s ease-out; }
  .btn-footer:disabled {
    background-color: #b3b3b3;
    color: #e6e6e6; }
  .btn-footer.focus:disabled, .btn-footer:focus:disabled, .btn-footer:hover:disabled {
    color: #fafafa; }

.light {
  font-weight: lighter; }

/*#locationSearch, .bigRoundedButton {
    opacity: 0.9;
    transition: opacity 0.5s ease;
}
#locationSearch:hover, .bigRoundedButton:hover,
#locationSearch:active, .bigRoundedButton:active {
    opacity: 1;
}
*/
.skeleton-box {
  background: rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 10px; }

.footer-toolbar {
  text-align: right;
  margin: 20px 10px 10px; }
  .footer-toolbar button {
    font-style: italic;
    color: #828b90;
    display: inline-block;
    padding: 0; }
    .footer-toolbar button:not(:last-child) {
      margin: 0 10px 0 0; }
  .footer-toolbar button + button {
    border-left: 1px solid #F3F3F3;
    padding-left: 10px; }
  .footer-toolbar .footer-toolbar--label {
    font-size: 12px;
    color: #828b90;
    padding: 5px 0; }

.revision-modal p {
  text-align: left; }

.revision-modal textarea {
  min-height: 100px;
  resize: none;
  margin: 12px 0;
  border: 3px solid #e6e6e6;
  width: 100%; }

.revision-modal .disclaimer {
  font-size: 16px;
  font-style: italic;
  color: #828b90; }

.top-toolbar {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 1px solid #e6e6e6; }
  .top-toolbar hr {
    height: 50px;
    margin: 0;
    border-top: 0; }
  .top-toolbar .toolbar-item {
    text-align: center;
    cursor: pointer;
    border: 0;
    background: none;
    color: #30bb6a;
    width: 100%;
    padding: 12px 0 6px;
    filter: grayscale(100%);
    backface-visibility: hidden;
    transition: all 0.2s ease; }
    .top-toolbar .toolbar-item .toolbar-item-icon {
      width: 25px;
      height: 25px;
      transition: transform 0.35s cubic-bezier(0, 0, 0, 2.41); }
      .top-toolbar .toolbar-item .toolbar-item-icon.glyphicon {
        font-size: 20px;
        line-height: 30px; }
    .top-toolbar .toolbar-item label {
      display: block;
      text-transform: uppercase;
      padding: 5px;
      pointer-events: none;
      margin: 0;
      font-size: 10px; }
    .top-toolbar .toolbar-item:hover {
      filter: none;
      text-decoration: none;
      background: #fafafa; }
      .top-toolbar .toolbar-item:hover .toolbar-item-icon {
        transform: scale(1.2); }
  .top-toolbar .toolbar-item + .toolbar-item {
    border-left: 1px solid #e6e6e6; }

#hamburger-menu .side-nav__footer {
  margin-left: 56px; }

#hamburger-menu .side-nav__content {
  margin: 56px 0 0 56px; }

@media screen and (max-height: 512px) {
  #hamburger-menu .side-nav__header {
    display: none; }
  #hamburger-menu .side-nav__hide {
    color: black;
    left: auto; }
  #hamburger-menu .side-nav__content {
    margin-top: 50px; } }

#hamburger-menu .badge {
  background: #30bb6a; }

#filter-menu .side-nav__header {
  height: auto;
  background: none;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

#filter-menu .side-nav__container {
  padding: 0 0 0 20px; }

#filter-menu .side-nav__hide {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #30bb6a;
  color: white;
  top: auto;
  width: calc(100% - 12px);
  margin: 6px;
  border-radius: 5px;
  z-index: 1000; }
  #filter-menu .side-nav__hide:hover, #filter-menu .side-nav__hide:active, #filter-menu .side-nav__hide:focus {
    background-color: #2cd978; }

#filter-menu .setting-checkbox-container {
  display: flex; }
  #filter-menu .setting-checkbox-container input + label {
    color: #828b90;
    font-size: 14px;
    cursor: pointer;
    margin-left: 5px; }

#filter-menu ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 6px; }
  #filter-menu ul li {
    align-items: baseline;
    white-space: nowrap;
    line-height: normal;
    padding: 0 6px;
    margin: 0 6px;
    width: calc(50% - 12px);
    border-radius: 10px; }
    #filter-menu ul li input:checked + label {
      font-weight: bold; }
    #filter-menu ul li label {
      flex-grow: 1;
      font-weight: normal;
      cursor: pointer;
      padding: 12px; }
      #filter-menu ul li label img {
        height: 1em;
        vertical-align: baseline;
        margin: 0; }

#filter-menu h2 {
  color: #333;
  display: inline-block; }

#filter-menu #clear-filters-btn {
  opacity: 0;
  font-size: 16px;
  border: none;
  color: #30bb6a;
  font-weight: bold; }

#filter-menu #filter-results-counter-container {
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  opacity: 0;
  color: white;
  background: #705EC7;
  padding: 5px;
  margin-left: -20px;
  padding-left: 36px; }

#filter-menu #filter-results-counter {
  font-weight: bold; }

@media screen and (max-height: 700px) {
  #filter-menu hr {
    display: none; } }

.gm-bundled-control .gmnoprint > div {
  border-radius: 20px; }

.pac-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  padding-right: 12px;
  /* Overrides marker icon */ }
  .pac-container .pac-item-query {
    font-size: 16px; }
  .pac-container .pac-item {
    font-size: 12px;
    display: block;
    padding: 10px;
    cursor: pointer;
    border: none; }
  .pac-container .pac-item > span:last-child {
    display: block;
    padding-left: 22px;
    font-family: "Quicksand", sans-serif;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis; }
  .pac-container .pac-icon {
    background-image: url(/img/icon_pin.svg);
    background-size: 19px;
    background-position: -2px 0px !important; }

.welcome-message-container .welcome-message {
  display: none;
  position: fixed;
  width: 100%;
  text-align: left;
  flex-wrap: wrap;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  bottom: 12px;
  left: 12px;
  font-size: 16px;
  height: auto;
  padding: 48px 48px 24px 24px;
  background: #30bb6a;
  color: white; }
  .welcome-message-container .welcome-message span {
    font-weight: normal; }
  .welcome-message-container .welcome-message a {
    color: white;
    font-weight: bold; }
  .welcome-message-container .welcome-message h1, .welcome-message-container .welcome-message h2 {
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #333;
    opacity: 0; }
  .welcome-message-container .welcome-message img {
    display: none;
    position: absolute;
    left: 10px;
    width: 60px;
    height: auto; }
  .welcome-message-container .welcome-message aside {
    position: absolute;
    bottom: 5px;
    right: 13px;
    font-size: 12px;
    opacity: 0.8; }
  .welcome-message-container .welcome-message .welcome-message--close {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: 0 0;
    border: 0;
    position: absolute;
    top: 0;
    right: 0; }
    .welcome-message-container .welcome-message .welcome-message--close:hover {
      background: rgba(0, 0, 0, 0.1); }
  .welcome-message-container .welcome-message .welcome-message--content {
    font-weight: normal; }
  .welcome-message-container .welcome-message {
    max-width: 400px;
    opacity: 1; }
  .welcome-message-container .welcome-message img {
    display: inline-block; }

.infoBox {
  height: 110px;
  background: white;
  display: flex;
  margin-top: 24px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }
  .infoBox:active, .infoBox:focus, .infoBox:hover {
    background: #fafafa; }
  .infoBox .numreviews {
    color: #828b90; }
  .infoBox .infobox--img {
    position: relative;
    flex: 1 0 30%; }
    .infoBox .infobox--img img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
  .infoBox .infobox--content {
    margin: auto 12px;
    overflow: hidden;
    width: 100%; }
    .infoBox .infobox--content h2 {
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.02em;
      line-height: 1.2em;
      margin: 0;
      max-height: 2.5em;
      overflow: hidden; }
    .infoBox .infobox--content .rating-display-container {
      margin-top: 8px;
      margin-bottom: 2px; }
    .infoBox .infobox--content .numreviews {
      font-weight: 400;
      font-size: 14px; }
  .infoBox .infobox--details {
    color: #828b90;
    font-size: 12px;
    font-family: "Quicksand", sans-serif; }
  .infoBox .infobox--label {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #b3b3b3;
    font-size: 10px;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
  .infoBox .infobox--caret {
    display: none; }
  .infoBox .infobox--arrow {
    display: block;
    position: absolute;
    top: -13px;
    left: calc(50% - 10px);
    font-size: 20px;
    color: white;
    text-shadow: 1px -1px 2px rgba(0, 0, 0, 0.1); }

.coverage-notice-modal .swal2-content {
  text-align: left; }

.coverage-notice-modal .panel .panel-title {
  font-size: 18px; }

.coverage-notice-modal .panel .panel-title {
  padding-left: 0;
  padding-right: 0; }

.share-modal .swal2-image {
  filter: grayscale(100%); }

.share-modal .share-icons {
  display: flex;
  justify-content: center; }
  .share-modal .share-icons > * {
    margin-right: 6px; }
  .share-modal .share-icons .share-email-btn {
    padding: 5px 11px 8px;
    background: #828b90;
    border-radius: 4px;
    line-height: 0;
    border: 0;
    min-width: 0; }
    .share-modal .share-icons .share-email-btn:hover {
      background: #333; }
    .share-modal .share-icons .share-email-btn .glyphicon.glyphicon-envelope {
      margin-right: 5px; }
    .share-modal .share-icons .share-email-btn a {
      color: white;
      font-size: 13px; }
      .share-modal .share-icons .share-email-btn a, .share-modal .share-icons .share-email-btn a:hover {
        text-decoration: none;
        border: 0; }

.share-modal .share-url-container {
  position: relative;
  background: #F3F3F3;
  transition: background 0.2s ease-out;
  border: 1px solid #828b90;
  border-radius: 5px; }
  .share-modal .share-url-container:hover, .share-modal .share-url-container:focus, .share-modal .share-url-container:active {
    background: #30bb6a; }
    .share-modal .share-url-container:hover > *, .share-modal .share-url-container:focus > *, .share-modal .share-url-container:active > * {
      color: white; }
  .share-modal .share-url-container .share-url-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #b3b3b3; }
  .share-modal .share-url-container textarea {
    width: 100%;
    text-align: center;
    color: #828b90;
    background: transparent;
    border: 0;
    resize: none;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    text-indent: 22px;
    padding: 12px 8px; }

.img-mosaic {
  display: flex !important;
  align-items: baseline;
  justify-content: space-evenly;
  margin: 4em 0; }
  .img-mosaic img {
    width: 12%; }

@media screen and (max-width: 430px) {
  .modal-body .place-warning {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 108px;
    padding-right: 48px; }
  .modal-body .request-done {
    padding: 24px 24px 12px 60px; }
  *:focus,
  *:active {
    outline: none; }
  .desktop-only {
    display: none; }
  #map {
    top: 56px;
    height: calc(100% - 56px); }
    #map.directions-active {
      height: 50%; }
  #logo {
    left: 0;
    margin-left: 72px;
    max-width: calc(100% - 84px); }
    #logo img {
      width: 170px; }
    #logo span {
      color: #FFF;
      font-size: 26px;
      margin-botton: 5px;
      display: inline-block; }
  .navbar-de-boa {
    display: none; }
  .modal:before {
    content: ''; }
  .modal .modal-header,
  .modal .modal-body {
    padding: 24px 12px; }
  .modal .modal-header {
    background: transparent;
    border: 0;
    color: #333; }
    .modal .modal-header h1 {
      padding: 0;
      margin: 0; }
  .modal .modal-dialog {
    margin: 0;
    background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%); }
  .modal .modal-content {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-height: 100vh;
    max-width: 100vw;
    padding-top: 56px;
    margin: 0; }
  .modal .title h1 {
    margin-top: 0; }
  .modal .photo-container {
    border-radius: 0; }
    .modal .photo-container.hasImage {
      height: 30vh;
      min-height: 0; }
    .modal .photo-container.hasImage:not(.loading):after {
      padding: 8px;
      border-radius: 5px;
      font-size: 15px; }
  .modal.fullscreen-modal article {
    margin-left: inherit;
    margin-right: inherit; }
  .modal.fullscreen-modal .modal-content {
    background: white; }
  #newPlaceModal .stickyFooterBtn {
    display: none; }
  #locationSearch .search-fields {
    height: 46px;
    margin: 5px 70px 0 70px;
    padding: 0 0 0 10px;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    resize: horizontal;
    background: #fafafa;
    width: calc(100% - 140px);
    border-radius: 10px; }
  #locationSearch #clearLocationQueryBtn {
    right: 20px;
    height: 48px;
    top: 4px; }
  #locationSearch.directions .search-fields {
    width: calc(100% - 100px); }
  #locationSearch.directions #clearLocationQueryBtn {
    display: none; }
  #toast-container > div {
    width: 100% !important;
    margin: 0; }
  #dataModal .data-modal--block-icon {
    display: none; }
  #dataModal .data-modal--block-content p {
    font-size: 12px; }
  #userBtn {
    top: 4px;
    right: 6px; }
    #userBtn > button {
      padding: 13px; }
      #userBtn > button .avatar {
        background: none;
        border: none;
        box-shadow: none;
        width: 24px;
        height: 24px;
        margin: 0; }
    #userBtn.admin > button {
      padding: 11px; }
      #userBtn.admin > button .avatar {
        width: 28px;
        height: 28px; }
    #userBtn .userBtn--user-name {
      display: none; }
  #topCitiesModal .top-cities li {
    width: 100%; }
    #topCitiesModal .top-cities li:nth-child(odd) {
      padding-right: 0; }
  #aboutModal .illustation,
  #privacyModal .illustation,
  .guide-modal .illustation {
    margin: 16px auto 32px; }
  #aboutModal p,
  #privacyModal p,
  .guide-modal p {
    font-size: 16px;
    line-height: 1.4em; }
  #aboutModal p.subtitle,
  #privacyModal p.subtitle,
  .guide-modal p.subtitle {
    font-size: 18px;
    line-height: 1.5em;
    letter-spacing: .01em; }
  #aboutModal h1,
  #privacyModal h1,
  .guide-modal h1 {
    font-size: 38.832px;
    margin-bottom: .5em; }
  #aboutModal h2,
  #privacyModal h2,
  .guide-modal h2 {
    font-size: 25.888px;
    margin-top: 2em;
    margin-bottom: .5em; }
  #aboutModal .social-link,
  #privacyModal .social-link,
  .guide-modal .social-link {
    font-size: 12px; }
  .guide-modal .close-and-filter {
    display: none; }
  .guide-modal .guide-img-row {
    justify-content: left;
    width: calc(100vw);
    overflow: auto;
    margin-right: -12px;
    margin-left: -12px; }
    .guide-modal .guide-img-row img {
      width: calc((100vw - 24px) / 2.5);
      height: calc((100vw - 24px) / 2.5); }
  .review-modal p.small {
    display: none; }
  .review-modal .tagDisplay {
    width: calc(50% - 12px); }
  .info img {
    width: 60px; }
  .swal2-modal .swal2-content,
  .swal2-modal p {
    font-size: 16px; }
  .swal2-modal .swal2-cancel,
  .swal2-modal .swal2-confirm {
    display: block;
    width: 100%;
    margin: 6px 0; }
  .revision-modal .disclaimer {
    display: none; }
  body.position-pin-mode .hamburger-button .hamburger-inner,
  body.position-pin-mode .hamburger-button .hamburger-inner::before,
  body.position-pin-mode .hamburger-button .hamburger-inner::after {
    background-color: white; }
  body.position-pin-mode #userBtn,
  body.position-pin-mode #search-button {
    display: none; }
  body.position-pin-mode #top-mobile-bar {
    height: 76px; }
    body.position-pin-mode #top-mobile-bar #top-mobile-bar-title {
      color: white; }
    body.position-pin-mode #top-mobile-bar .search-button {
      display: none; }
  #locationSearch {
    opacity: 0;
    pointer-events: none;
    width: 100%;
    top: 0;
    max-width: none; }
  body.search-mode #locationSearch {
    position: fixed;
    opacity: 1;
    pointer-events: auto;
    height: 56px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }
    body.search-mode #locationSearch.directions {
      height: 112px;
      background-image: url("/img/icon_directions_form.svg");
      background-repeat: no-repeat;
      background-size: 11px;
      background-position-x: 55px;
      background-position-y: 24px; }
  body.search-mode .hamburger-button .hamburger-inner,
  body.search-mode .hamburger-button .hamburger-inner::before,
  body.search-mode .hamburger-button .hamburger-inner::after {
    background-color: #30bb6a; }
  body.modal-open #map,
  body.modal-open #addPlace,
  body.modal-open #geolocationBtn,
  body.modal-open .search-button {
    opacity: 0;
    pointer-events: none; }
  body.modal-open #close-modal-btn,
  body.modal-open .search-button,
  body.modal-open #list-view {
    display: none; }
  body.modal-open #top-mobile-bar {
    background: white; }
    body.modal-open #top-mobile-bar #top-mobile-bar-title {
      color: #333; }
  body.modal-open.transparent-mobile-topbar #top-mobile-bar {
    background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    position: absolute;
    box-shadow: none;
    border: none; }
  body.modal-open.transparent-mobile-topbar .hamburger-button {
    position: absolute; }
    body.modal-open.transparent-mobile-topbar .hamburger-button .hamburger-inner,
    body.modal-open.transparent-mobile-topbar .hamburger-button .hamburger-inner::before,
    body.modal-open.transparent-mobile-topbar .hamburger-button .hamburger-inner::after {
      background-color: white; }
  body.modal-open.transparent-mobile-topbar .modal .modal-content {
    padding-top: 0; }
  body.modal-open #top-mobile-bar,
  body.modal-open .hamburger-button {
    z-index: 1052; }
  body.modal-open.newPlaceModal #top-mobile-bar .saveNewPlaceBtn {
    display: block;
    top: 4px;
    right: 6px;
    position: absolute;
    width: auto;
    font-weight: bold;
    color: #30bb6a; }
  body.details-view:not(.already-reviewed) #bottom-mobile-bar {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    transform: none; }
  #bottom-mobile-bar {
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: transform 0.8s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 0.4s ease-in-out; }
  #top-mobile-bar {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    background: #30bb6a;
    transition: all .3s ease-out;
    will-change: height; }
    #top-mobile-bar .saveNewPlaceBtn {
      display: none; }
    #top-mobile-bar .search-button {
      top: 4px;
      right: 52px;
      position: fixed;
      padding: 14px;
      height: 48px; }
    #top-mobile-bar #top-mobile-bar-title {
      text-align: left;
      margin: 0;
      margin-left: 72px;
      padding: 0;
      font-size: 16px;
      font-family: "Quicksand", sans-serif;
      max-width: calc(100% - 84px);
      color: white;
      line-height: 1.2em; }
    #top-mobile-bar button {
      display: inline-block;
      height: 48px;
      width: 48px;
      background: transparent;
      border: 0; }
      #top-mobile-bar button:focus, #top-mobile-bar button:active {
        background-color: #04883A; }
    #top-mobile-bar .back-button {
      float: left;
      background: url(/img/icon_back_white.svg) 2px 10px no-repeat;
      background-size: 50px; }
  #placeDetailsContent hr {
    margin-left: -12px;
    margin-right: -12px; }
  #placeDetailsContent .tagDisplayContainer {
    width: calc(50% - 12px); }
  #placeDetailsContent .review #new-review-btn {
    display: none; }
  #placeDetailsContent .tagsContainer {
    margin-top: 2em;
    justify-content: flex-start; }
    #placeDetailsContent .tagsContainer .tagDisplay {
      font-size: 14px;
      width: 49%; }
  #placeDetailsContent .top-toolbar hr {
    height: 60px; }
  #placeDetailsContent .top-toolbar .toolbar-item label {
    font-size: 8px; }
  #placeDetailsContent .top-toolbar .toolbar-item .toolbar-item-icon {
    width: 20px;
    height: 20px; }
  #filter-menu .side-nav__container {
    width: 100%;
    padding: 0; }
  #filter-menu ul li {
    font-size: 16px; }
  #filter-menu #filter-results-counter-container {
    padding-left: 16px;
    margin: 0; }
    #filter-menu #filter-results-counter-container .glyphicon.glyphicon-chevron-left {
      display: none; }
  #hamburger-menu .side-nav__footer,
  #hamburger-menu .side-nav__content {
    margin-left: 0; }
  .map-action-buttons {
    transition: all 0.2s;
    position: absolute;
    bottom: 0;
    right: 0; }
    .map-action-buttons.move-up {
      transform: translateY(-90px); }
    .map-action-buttons.hide {
      display: none; }
  .infoBox {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 24px;
    box-shadow: 0 10px 30px #c8c8c8;
    animation: slideInDownBig 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }
    .infoBox .infobox--content {
      margin-right: 36px; }
    .infoBox .infobox--label {
      display: none; }
    .infoBox .infobox--arrow {
      display: none; }
    .infoBox .infobox--caret {
      display: block;
      color: #30bb6a;
      position: absolute;
      right: 12px;
      top: calc(50% - 10px);
      font-size: 20px; }
  .share-modal .share-email-btn .share-email-label {
    display: none; }
  #newRequestOrPlaceModal button {
    padding: 5px;
    background: #FFF; }
    #newRequestOrPlaceModal button:active {
      background: #F2F2F2; }
    #newRequestOrPlaceModal button h3 {
      margin-top: 40px;
      width: calc(100% - 80px); }
    #newRequestOrPlaceModal button p {
      clear: both;
      width: 100%;
      padding: 0 20px; }
    #newRequestOrPlaceModal button img {
      width: 50px; } }

.place-suggestions {
  margin: 6px 0; }
  .place-suggestions .place-suggestions--title {
    display: block;
    font-size: 12px;
    font-weight: normal;
    user-select: none;
    margin-right: 3px;
    color: #b3b3b3; }
  .place-suggestions .place-suggestions--list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline; }
    .place-suggestions .place-suggestions--list .place-suggestion--item {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      padding: 6px;
      background: #F3F3F3;
      border-radius: 4px;
      margin: 0;
      cursor: pointer;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #828b90; }
      .place-suggestions .place-suggestions--list .place-suggestion--item a {
        color: #828b90; }
      .place-suggestions .place-suggestions--list .place-suggestion--item:hover a {
        color: #333; }

.featherlight:last-of-type {
  background: white; }

.featherlight .featherlight-content {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent; }
  .featherlight .featherlight-content .featherlight-caption {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 6px;
    text-align: center;
    right: 0;
    word-wrap: break-word;
    font-size: 12px; }

.featherlight .featherlight-close-icon {
  position: fixed;
  color: #333;
  top: 12px;
  right: 24px;
  font-size: 30px;
  background: 0 0;
  z-index: 1052;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  text-shadow: 0 0 3px white;
  transition: background .2s; }
  .featherlight .featherlight-close-icon:hover {
    background: #e6e6e6; }

a[data-featherlight] img:hover:after {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  content: "\e096";
  font-family: "Glyphicons Halflings";
  line-height: 1;
  pointer-events: none; }

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); } }

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); } }

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); } }

.confetti-placeholder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  pointer-events: none; }

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%; }
  .confetti--animation-slow {
    animation: confetti-slow 3.15s linear 1 forwards; }
  .confetti--animation-medium {
    animation: confetti-medium 2.25s linear 1 forwards; }
  .confetti--animation-fast {
    animation: confetti-fast 1.75s linear 1 forwards; }

/****** Style Star Rating Widget *****/
/* https://codepen.io/jamesbarnett/pen/vlpkh */
.rating {
  display: inline-block;
  vertical-align: middle; }
  .rating > input {
    display: none; }
  .rating > label {
    color: #e6e6e6;
    float: right;
    margin: 0; }
    .rating > label:before {
      margin: 0 2px;
      font-size: 34px;
      font-family: "Glyphicons Halflings";
      display: inline-block;
      transition: color 0.2s ease, text-shadow 0.2s ease;
      content: '';
      background-image: url(/img/icon_star_gray.svg);
      background-size: 100%;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat; }
  .rating:not(.disabled) > label {
    cursor: pointer; }
  .rating.rating-big > label:before {
    font-size: 35px;
    margin: 0 10px; }
  .rating.empty > label:before {
    content: ''; }
  .rating > input:checked ~ label:before {
    background-image: url(/img/icon_star_yellow.svg); }

.rating-display-container[data-color="green"] > .rating > input:checked ~ label:before,
.rating-display-container[data-color="green"] > .rating:not(:checked):not(.disabled) > label:hover:before,
.rating-display-container[data-color="green"] > .rating:not(:checked):not(.disabled) > label:hover ~ label:before {
  background-image: url(/img/icon_star_green.svg); }

.rating-display-container[data-color="yellow"] > .rating > input:checked ~ label:before,
.rating-display-container[data-color="yellow"] > .rating:not(:checked):not(.disabled) > label:hover:before,
.rating-display-container[data-color="yellow"] > .rating:not(:checked):not(.disabled) > label:hover ~ label:before {
  background-image: url(/img/icon_star_yellow.svg); }

.rating-display-container[data-color="red"] > .rating > input:checked ~ label:before,
.rating-display-container[data-color="red"] > .rating:not(:checked):not(.disabled) > label:hover:before,
.rating-display-container[data-color="red"] > .rating:not(:checked):not(.disabled) > label:hover ~ label:before {
  background-image: url(/img/icon_star_red.svg); }

.loading-skeleton .rating > label:before {
  opacity: 0.5; }

[data-color="green"], .color-green {
  color: #30bb6a; }

[data-color="yellow"], .color-yellow {
  color: #F6C913; }

[data-color="red"], .color-red {
  color: #FF8265; }

[data-color="gray"], .color-gray {
  color: #828b90; }

.rating-display-container[data-color="green"] .average,
.average[data-color="green"] {
  background-color: #30bb6a; }

.rating-display-container[data-color="yellow"] .average,
.average[data-color="yellow"] {
  background-color: #F6C913; }

.rating-display-container[data-color="red"] .average,
.average[data-color="red"] {
  background-color: #FF8265; }

@media screen and (min-width: 430px) {
  .rating:not(:checked):not(.disabled) > label:hover:before,
  .rating:not(:checked):not(.disabled) > label:hover ~ label:before {
    /* hover previous stars in list */
    background-image: url(/img/icon_star_yellow.svg); } }

.review-modal .rating > label:before {
  margin: 5px 6px;
  width: 40px;
  height: 40px; }

/* Custom Radio Buttons */
.radio-group label {
  cursor: pointer;
  color: #b3b3b3;
  margin-bottom: 0;
  transition: color 0.2s ease; }

.radio-group label:hover,
.radio-group label:focus {
  color: #1e1e1e; }

.radio-group label + label {
  margin-left: 10px; }

.radio-group label > input {
  /* HIDE RADIO */
  visibility: hidden;
  /* Makes input not-clickable */
  position: absolute;
  /* Remove input from document flow */
  /* Preloads the checked state image to prevent flicker when first activating the button */
  background-image: url(/img/radio_checked.svg); }

.radio-group label > input + .radio-image {
  /* IMAGE STYLES */
  background-image: url(/img/radio.svg);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  background-size: 100%;
  display: inline-block;
  vertical-align: bottom;
  transition: background-image 0.2s ease-in-out; }

.radio-group label > input:checked + .radio-image {
  /* (RADIO CHECKED) IMAGE STYLES */
  background-image: url(/img/radio_checked.svg); }

.rating-display-mini .average,
.rating-display-mini .rating > label:before {
  font-size: 14px; }

.rating-display-mini .rating > label:before {
  width: 14px;
  height: 14px; }

.request-mini .average {
  margin-right: 10px; }

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
  /* This makes sure that we can still click outside of the modal to close it */ }

@media screen and (min-height: 768px) {
  .vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none; } }

.modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width: inherit;
  height: inherit;
  /* To center horizontally */
  margin-left: auto;
  margin-right: auto;
  pointer-events: all; }

/**
 *
 * Copyright 2016 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.hamburger-button {
  position: fixed;
  top: 4px;
  left: 6px;
  width: 48px;
  height: 48px;
  color: white;
  border: none;
  margin: 0;
  padding: 14px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  background-color: transparent; }
  .hamburger-button.back-icon .hamburger-inner {
    transform: rotate(-180deg); }
    .hamburger-button.back-icon .hamburger-inner, .hamburger-button.back-icon .hamburger-inner::before, .hamburger-button.back-icon .hamburger-inner::after {
      background-color: #30bb6a; }
    .hamburger-button.back-icon .hamburger-inner::before {
      transform: translate3d(6px, 3px, 0) rotate(45deg) scale(0.7, 1); }
    .hamburger-button.back-icon .hamburger-inner::after {
      transform: translate3d(6px, -3px, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger-button.close-icon .hamburger-inner {
    background: transparent; }
    .hamburger-button.close-icon .hamburger-inner::before, .hamburger-button.close-icon .hamburger-inner::after {
      background-color: #30bb6a; }
    .hamburger-button.close-icon .hamburger-inner::before {
      transform: translate3d(0px, 7px, 0) rotate(45deg); }
    .hamburger-button.close-icon .hamburger-inner::after {
      transform: translate3d(0px, -7px, 0) rotate(-45deg); }
  .hamburger-button .hamburger-box {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative; }
  .hamburger-button .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
    .hamburger-button .hamburger-inner, .hamburger-button .hamburger-inner::before, .hamburger-button .hamburger-inner::after {
      width: 20px;
      height: 3px;
      background-color: white;
      border-radius: 25px;
      position: absolute;
      transition-property: transform, background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-out; }
    .hamburger-button .hamburger-inner::before, .hamburger-button .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-button .hamburger-inner::before {
      top: -7px; }
    .hamburger-button .hamburger-inner::after {
      bottom: -7px; }

.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; }
  .side-nav::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out; }

.side-nav-inverted .side-nav__container {
  position: absolute;
  right: 0;
  transform: translateX(102%); }
  .side-nav-inverted .side-nav__container .side-nav__hide {
    right: 0;
    left: auto; }

.side-nav__container {
  position: relative;
  width: 90%;
  max-width: 450px;
  background: #FFF;
  height: 100%;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.4);
  transform: translateX(-105%);
  display: flex;
  flex-direction: column;
  will-change: transform; }
  .side-nav__container .side-nav__hide {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 48px;
    height: 48px;
    font-size: 20px;
    background: none;
    border: none;
    color: #333;
    padding: 0;
    margin: 0; }
    .side-nav__container .side-nav__hide:hover {
      background: rgba(0, 0, 0, 0.1); }
  .side-nav__container .side-nav__header {
    font-family: "Quicksand", sans-serif;
    height: 200px;
    background: #30bb6a;
    background: linear-gradient(-45deg, #30bb6a 0%, #2cd978 100%);
    color: #FFF;
    padding: 16px;
    font-size: 30px; }
    .side-nav__container .side-nav__header img {
      width: 100%;
      height: 100%;
      padding: 8% 5% 3%;
      opacity: .9;
      vertical-align: bottom;
      transition: opacity 0.5s ease-out; }
      .side-nav__container .side-nav__header img:hover {
        opacity: 1; }
  .side-nav__container .side-nav__footer {
    padding: 8px 16px 0;
    background: #F3F3F3; }
    .side-nav__container .side-nav__footer .social-links-list {
      display: flex;
      margin-left: -14px; }
  .side-nav__container .side-nav__content {
    flex: 1;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 18px;
    padding-bottom: 90px; }
    .side-nav__container .side-nav__content h3 {
      padding: 10px;
      font-weight: bold;
      font-size: 12px;
      color: #b3b3b3;
      user-select: none;
      padding: 10px 0 0 16px;
      text-transform: uppercase; }
    .side-nav__container .side-nav__content hr {
      margin-left: 16px; }
    .side-nav__container .side-nav__content ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .side-nav__container .side-nav__content ul .glyphicon {
        margin-right: 5px; }
      .side-nav__container .side-nav__content ul li {
        height: 48px;
        line-height: 24px;
        padding: 0 16px;
        cursor: pointer; }
        .side-nav__container .side-nav__content ul li img {
          height: 1em;
          max-width: 1em;
          margin-right: 5px; }

.side-nav--animatable .side-nav__container {
  transition: transform 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }

.side-nav--visible.side-nav--animatable .side-nav__container {
  transition: transform 0.3s cubic-bezier(0.39, 0.58, 0.57, 1); }

.side-nav--visible::before {
  opacity: 1; }

.side-nav--visible .side-nav__container {
  transform: none; }

.side-nav--visible {
  pointer-events: auto; }

.tooltip-modal {
  box-shadow: none !important;
  background: #30bb6a !important; }
  .tooltip-modal .swal2-content,
  .tooltip-modal .swal2-close,
  .tooltip-modal a {
    color: white !important; }
  .tooltip-modal p {
    text-align: left;
    font-size: 14px; }

.swal2-show,
.swal2-hide {
  animation: slideIn 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }

.swal2-hide {
  animation: slideOut 0.2s cubic-bezier(0.39, 0.58, 0.57, 1); }

.swal2-modal {
  padding: 48px 5% 5% !important;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); }
  .swal2-modal .swal2-buttonswrapper {
    margin-top: 24px; }
  .swal2-modal .swal2-content,
  .swal2-modal .swal2-title {
    color: #333; }
  .swal2-modal .swal2-close {
    border: 0; }
  .swal2-modal .swal2-content {
    font-weight: normal; }
  .swal2-modal .swal2-title {
    color: #333; }
  .swal2-modal button + button {
    margin-left: 12px; }
  .swal2-modal .swal2-cancel {
    background: #e6e6e6;
    border-color: #828b90; }
  .swal2-modal .swal2-confirm {
    background: #30bb6a;
    color: white; }
  .swal2-modal .swal2-cancel,
  .swal2-modal .swal2-confirm {
    border: 0;
    border-radius: 5px;
    font-weight: 700;
    min-width: 90px;
    transition: all .2s; }
    .swal2-modal .swal2-cancel:hover:not([disabled]),
    .swal2-modal .swal2-confirm:hover:not([disabled]) {
      transform: translateY(-1px);
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); }
    .swal2-modal .swal2-cancel:active,
    .swal2-modal .swal2-confirm:active {
      outline: 0;
      border-bottom-width: 0; }
  .swal2-modal .sa-button-container {
    margin-top: 30px; }

#toast-container > div {
  font-family: "Quicksand", sans-serif;
  opacity: 0.95;
  padding: 24px 24px 24px 50px; }

#toast-container .toast-title {
  font-weight: bold;
  /* text-transform: uppercase; */
  margin-top: .5em;
  margin-bottom: .25em;
  font-size: 18px; }

#toast-container > .toast-success {
  background-color: #30bb6a; }

#toast-container > .toast-info {
  background-color: #533FB4;
  padding: 12px;
  background-image: none !important; }

#toast-container > .toast-warning {
  background-color: #F6C913; }

.cluster {
  transition: filter 0.2s ease; }
  .cluster:hover {
    filter: brightness(1.05); }
  .cluster img {
    width: 100%;
    height: 100%; }
  .cluster div {
    font-family: "Quicksand", sans-serif !important;
    color: white !important;
    font-weight: normal !important; }

.markerLabel {
  display: none;
  opacity: 0.7;
  font-family: "Quicksand", sans-serif;
  font-size: 10px;
  text-shadow: 0 1px 1px white;
  padding-left: 3px;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  width: 70px;
  max-height: 26px;
  margin: 0; }

#welcomeModal {
  text-align: center;
  font-size: 16px; }
  #welcomeModal h1 {
    font-size: 24px;
    font-weight: bold;
    color: #30bb6a;
    margin-bottom: 20px; }
    #welcomeModal h1 b {
      display: block;
      font-size: 26px; }
  #welcomeModal ul {
    margin: 30px 0;
    list-style: none; }
    #welcomeModal ul li {
      clear: both;
      margin: 20px 0;
      text-align: left; }
      #welcomeModal ul li img {
        display: block;
        float: left; }
      #welcomeModal ul li div {
        margin-left: 65px; }
      #welcomeModal ul li .first {
        line-height: 45px; }
  #welcomeModal button {
    padding-left: 30px;
    padding-right: 30px; }

.mmap-modal .title {
  color: #98344C; }

.mmap-modal .primary-btn {
  padding: 5px 10px;
  border: 2px solid #98344C;
  color: #fff;
  margin: 5px auto;
  font-size: 16px;
  border-radius: 25px;
  vertical-align: middle;
  background: #98344C;
  transition: all .2s; }
  .mmap-modal .primary-btn:hover, .mmap-modal .primary-btn:focus, .mmap-modal .primary-btn:active {
    color: white;
    border-color: transparent;
    background-color: #2cd978; }

.mmap-modal .secondary-btn {
  border: 2px solid #98344C;
  color: #98344C; }

.mmap-modal .form-input {
  width: 100%;
  display: block;
  margin: 10px 0px 30px 0px;
  border: none;
  padding: 10px 10px;
  border-bottom: 1px solid #737B7D; }

#bottomsheet-rotas {
  position: absolute;
  width: 100%;
  min-height: 80px;
  bottom: 0px;
  z-index: 20;
  background: #FFF;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0 0 0 0;
  box-shadow: 0px -1px 3px 0px #ababab; }
  #bottomsheet-rotas.list-directions-active {
    top: calc(50% +  56px);
    height: auto; }
  #bottomsheet-rotas #list-directions {
    background: #FFF;
    padding: 20px 20px 0 20px; }
    #bottomsheet-rotas #list-directions .adp-summary {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 18px; }
    #bottomsheet-rotas #list-directions .adp-substep {
      padding: 20px 0; }
    #bottomsheet-rotas #list-directions .adp-substep:nth-of-type(2) {
      color: #fff; }
  #bottomsheet-rotas .list-action-btns {
    margin: 0;
    padding: 10px 0; }
    #bottomsheet-rotas .list-action-btns .action-btns-item {
      width: 49%;
      display: inline-block;
      margin: 0;
      padding: 0 20px; }
    #bottomsheet-rotas .list-action-btns .item-btn {
      width: 100%; }
      #bottomsheet-rotas .list-action-btns .item-btn.hide {
        display: none; }
    #bottomsheet-rotas .list-action-btns .btn {
      border-radius: 25px;
      border: 1px solid #30bb6a;
      color: #30bb6a;
      line-height: 25px; }
    #bottomsheet-rotas .list-action-btns .btn.green {
      background: #30bb6a;
      color: #FFF; }
  #bottomsheet-rotas .directions-box {
    display: none;
    background: #FFF;
    padding: 0 0 40px 0; }
  #bottomsheet-rotas .adp-warnbox,
  #bottomsheet-rotas .adp-legal {
    display: none; }
  #bottomsheet-rotas .adp-placemark {
    display: none; }
  #bottomsheet-rotas .list-directions-actions-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff; }
  #bottomsheet-rotas .pull-up-container {
    text-align: center; }
  #bottomsheet-rotas .pull-up {
    display: inline-block;
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background-color: #828b90; }

@media screen and (min-width: 430px) {
  .hide-desktop {
    display: none; }
  #bottomsheet-rotas {
    width: 30%;
    min-width: 350px;
    height: calc(100% - 70px);
    overflow: hidden;
    top: 65px;
    border-radius: 0;
    z-index: 01; }
    #bottomsheet-rotas.list-directions-active {
      top: 65px;
      height: calc(100% - 70px); }
    #bottomsheet-rotas .adp-summary {
      text-align: center; }
    #bottomsheet-rotas .list-directions-actions-box {
      position: absolute;
      bottom: 5px;
      background: #fff; }
    #bottomsheet-rotas .directions-box {
      height: 100%;
      overflow: auto; }
    #bottomsheet-rotas #list-directions .adp-substep {
      padding: 35px 0; }
    #bottomsheet-rotas #locationSearch {
      position: relative;
      margin: 0;
      padding: 0;
      max-width: 100%;
      width: 100%; }
      #bottomsheet-rotas #locationSearch .search-fields {
        margin: 10px 0px 30px 30px;
        padding: 0 15px;
        width: 83%;
        background: #fafafa; }
      #bottomsheet-rotas #locationSearch #clearLocationQueryBtn {
        top: 10px;
        right: 40px; }
      #bottomsheet-rotas #locationSearch #closeSearchLocation {
        position: absolute;
        top: 10px;
        right: 7px;
        display: block;
        border: none;
        padding: 0 10px;
        background: #fff;
        font-size: 24px; }
    #bottomsheet-rotas.list-directions-active #clearLocationQueryBtn {
      display: none; }
    #bottomsheet-rotas.list-directions-active #locationSearch {
      background-image: url(/img/icon_directions_form.svg);
      background-repeat: no-repeat;
      background-size: 11px;
      background-position-x: 10px;
      background-position-y: 24px;
      margin-bottom: 10px; }
      #bottomsheet-rotas.list-directions-active #locationSearch .search-fields {
        margin: 10px 30px 0;
        padding: 0 15px;
        width: 85%; }
    #bottomsheet-rotas.list-directions-active .directions-box {
      display: block;
      height: calc(100% - 190px); }
  .pull-up-container {
    display: none; } }

#closeSearchLocation {
  display: none; }

body.directions {
  overflow: visible; }

#directions-messages {
  display: none;
  background: #e6e6e6;
  padding: 10px 20px;
  margin: 15px 0; }
  #directions-messages h1 {
    font-size: 20px; }
  #directions-messages .direction-msg {
    display: none; }

.filter-checkbox {
  display: none; }

.filter-list-item {
  width: 100px;
  height: 150px !important;
  margin-bottom: 30px !important; }

#label_bikelane {
  background-image: url(/img/filter_lane.png); }

#label_rack {
  background-image: url(/img/filter_rack.png); }

#label_bicipark {
  background-image: url(/img/filter_bicipark.png); }

#label_request {
  background-image: url(/img/filter_request.png); }

.filter-label {
  background-size: 100px;
  display: block;
  padding-top: 120px !important;
  background-repeat: no-repeat;
  text-align: center;
  background-position-x: center;
  background-position-y: 10px;
  width: 130px;
  border-radius: 10px;
  box-sizing: border-box; }
  .filter-label:hover {
    background-color: #F3F3F3; }

.filter-checkbox:checked + .filter-label {
  background-color: #F3F3F3;
  border: 1px solid #b3b3b3; }
